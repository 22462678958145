import Link from 'next/link';
import { createElement, FC } from 'react';

import IAttributedText from '@/components/AttributedText/index.d';
import { Text } from '@/components/Text';

const HighlightItem: FC<IAttributedText.HighlightedItem> = ({ item, wrapper, wrapperProps }) => {
	const highlightedItem = item.split('#');
	return createElement(wrapper, wrapperProps, highlightedItem[1]);
};

const Highlight = ({ content, items, wrapperProps, wrapper, className }: any) => {
	const list: Array<any> = [];
	let previous = 0;
	if (!items || items.length === 0) {
		return [
			<Text key={`attr-${content}-${items.length}`} component={{ className, component: 'span' }}>
				{content}
			</Text>,
		];
	}

	items.forEach((item: any, index: number) => {
		const start = content.indexOf(item);
		const end = start + item.length;
		const a = content.slice(previous, start);
		const b = content.slice(start, end);
		previous = end;

		list.push(
			<Text key={`attr-${a}-${String(index)}`} component={{ className, component: 'span' }}>
				{a}
			</Text>
		);
		list.push(
			<HighlightItem
				key={`attr-${b}-${String(index)}`}
				wrapper={wrapper}
				item={b}
				wrapperProps={wrapperProps}
			/>
		);

		if (index === items.length - 1) {
			const c = content.slice(end, content.length);
			list.push(
				<Text key={`attr-${c}-${String(index)}`} component={{ className, component: 'span' }}>
					{c}
				</Text>
			);
		}
	});

	return list;
};

const LinkWrapper: FC<IAttributedText.ILinkWrapper> = ({
	children,
	href,
	external = false,
	...props
}) => {
	if (external) {
		return (
			<a href={href} target="_blank" rel="noopener noreferrer" {...props}>
				{children}
			</a>
		);
	}
	return (
		<Link href={href}>
			<a {...props}>{children}</a>
		</Link>
	);
};

const AttributedText: FC<IAttributedText.IProps> = (props) => {
	const {
		children,
		wrapperProps,
		wrapper,
		dataCy,
		searchRegex = '\\#(.*?)\\#',
		className,
		textClassName = '',
	} = props;
	let wrapperComp = wrapper;
	const regex = new RegExp(searchRegex, 'g');
	const highlightedItems = children.match(regex);

	if (!highlightedItems) {
		return (
			<Text data-cy={dataCy} component={{ className: `${className || ''}`, component: 'span' }}>
				{children}
			</Text>
		);
	}

	if (wrapper === 'link') {
		wrapperComp = LinkWrapper;
	}

	const list = Highlight({
		className,
		content: children,
		items: highlightedItems,
		wrapper: wrapperComp,
		wrapperProps,
	});

	return (
		<Text
			data-cy={dataCy}
			component={{ className: `whitespace-pre-line ${textClassName}`, component: 'span' }}
		>
			{list.map((item) => item)}
		</Text>
	);
};
export default AttributedText;
