import IButtonGroup from '@/components/ButtonGroup/index.d';
import { IStoreAndAPIShared } from '@/redux/shared/index.d';
import { addNumberMonthToFrontendDate, ISOtoHumanFriendly } from '@/services/Utils/Date';
import { IInstallmentUtils } from '@/services/Utils/Installment/index.d';

const TOTAL_PAYMENT = 3000;
const MAX_INSTALLMENT = 10;

const getProductList = (item = MAX_INSTALLMENT) => {
	const itemList = [];

	for (let i = 1; i <= item; i += 1) {
		itemList.push({ id: i, label: i });
	}
	return itemList;
};

// eslint-disable-next-line consistent-return
const getInstallmentDate = (i: number, installment: number, t: any, minDepositLimit?: number) => {
	const iteration = 30;
	const minInstallment = minDepositLimit || 4;

	if (installment >= minInstallment && i === 1) {
		return t('common:todayDeposit');
	}
	if (installment >= minInstallment && i !== 1) {
		return t('common:nthDay', { day: `${iteration * (i - 1)}` });
	}
	if (installment < minInstallment) {
		return t('common:nthDay', { day: `${iteration * i}` });
	}
};

// @ts-ignore
const roundToTwo = (num: number) => +`${Math.round(`${num}e+2`)}e-2`;

// Only used in the Thirdparty calculator
export const getInstallmentListStaticFromProduct = (
	product: IStoreAndAPIShared.IProduct,
	total = TOTAL_PAYMENT,
	firstRepaymentDate: string,
	t: any
) => {
	const installmentList = [];
	let sumAmount = 0;
	const depositCutoff = parseFloat(product.deposit_percentage) * 100;

	let instalmentAmount;
	let deposit;
	let lastInstalmentAmount;

	if (Math.round(depositCutoff * product.instalments) === 100) {
		product.equal_instalment = true;
	}

	if (product.equal_instalment && product.deposit) {
		instalmentAmount = roundToTwo(total / product.instalments);
		deposit = instalmentAmount;
		lastInstalmentAmount = total - instalmentAmount * (product.instalments - 1);
	} else if (product.deposit) {
		instalmentAmount = roundToTwo(
			((total / 100) * (100 - depositCutoff)) / (product.instalments - 1)
		);
		deposit = roundToTwo((total / 100) * depositCutoff);
		lastInstalmentAmount = roundToTwo(
			total - deposit - instalmentAmount * (product.instalments - 2)
		);
	} else {
		instalmentAmount = roundToTwo(total / product.instalments);
		deposit = 0;
		lastInstalmentAmount = roundToTwo(total - instalmentAmount * (product.instalments - 1));
	}

	for (let i = 1; i <= product.instalments; i += 1) {
		if (product.deposit) {
			if (i === 1) {
				sumAmount += deposit as number;
				installmentList.push({
					amount: deposit,
					date: t('common:todayDeposit'),
					id: i,
					sumAmount,
					total,
				});
			} else {
				const amount = i === product.instalments ? lastInstalmentAmount : instalmentAmount;
				sumAmount += amount;
				installmentList.push({
					amount,
					date: firstRepaymentDate
						? addNumberMonthToFrontendDate(firstRepaymentDate, i - 2)
						: getInstallmentDate(i, product.instalments, t),
					id: i,
					sumAmount,
					total,
				});
			}
		} else {
			const amount = i === product.instalments ? lastInstalmentAmount : instalmentAmount;
			sumAmount += amount;
			installmentList.push({
				amount,
				date: firstRepaymentDate
					? addNumberMonthToFrontendDate(firstRepaymentDate, i - 1)
					: getInstallmentDate(i, product.instalments, t),
				id: i,
				sumAmount,
				total,
			});
		}
	}
	return installmentList;
};

// Creates an installment list from number of installments, whether deposit is true, total amount and translation object
const getInstallmentListStatic = ({
	installment = MAX_INSTALLMENT,
	total = TOTAL_PAYMENT,
	t,
	deposit,
	depositCutoff = 25,
	minDepositLimit = 4,
}: IInstallmentUtils.IGetInstallmentListStatic) => {
	const installmentList = [];
	let sumAmount = 0;

	for (let i = 1; i <= installment; i += 1) {
		if (deposit && installment >= minDepositLimit) {
			const flagIns = (total * depositCutoff) / 100;
			const flagTotal = total - flagIns;
			const amount = flagTotal / (installment - 1);
			if (i === 1) {
				sumAmount += flagIns;
				installmentList.push({
					amount: flagIns,
					date: getInstallmentDate(i, installment, t),
					id: i,
					sumAmount,
					total,
				});
			} else {
				sumAmount += amount;
				installmentList.push({
					amount,
					date: getInstallmentDate(i, installment, t),
					id: i,
					sumAmount,
					total,
				});
			}
		} else {
			const amount = total / installment;
			sumAmount += amount;
			installmentList.push({
				amount,
				date: getInstallmentDate(i, installment, t, minDepositLimit),
				id: i,
				sumAmount,
				total,
			});
		}
	}
	return installmentList;
};

// Gets the backend installment data and converts it to frontend version
const getInstallmentListViaData = (
	installments: IStoreAndAPIShared.IInstallment[],
	totalAmount: string,
	amountError: string,
	t: any,
	depositFullyPaid?: boolean
) => {
	if (amountError) {
		return [];
	}
	const total = parseFloat(totalAmount);

	if (Number.isNaN(total) || total === 0) {
		return [];
	}

	let sumAmount = 0;
	return installments?.map((installment: any) => {
		const amount = parseFloat(installment.amount);
		sumAmount += amount;
		let date =
			installment.type === 'Deposit' ? t('common:today') : ISOtoHumanFriendly(installment.duedate);
		if (depositFullyPaid && installment.type === 'Deposit') {
			date = t('common:paid');
		}
		return {
			amount,
			date,
			id: installment.instalment_number,
			sumAmount,
			total,
			type: installment.type,
		};
	});
};

// Same with the getInstallmentListViaData but for editAmount since it has some extra data, needs to be typed as well
const getInstallmentListViaEditAmountData = (
	installmentsData: any,
	totalAmount: string,
	amountError: string,
	t: any
) => {
	if (amountError) {
		return [];
	}
	const total = parseFloat(totalAmount);

	if (Number.isNaN(total) || total === 0) {
		return [];
	}

	let sumAmount = 0;
	return installmentsData?.instalments?.map((installment: any) => {
		const amount = parseFloat(installment.amount);
		sumAmount += amount;
		const date =
			installment.type === 'Deposit'
				? t('common:depositDesc', {
						date: ISOtoHumanFriendly(installment?.duedate),
						deposit: installment?.paid_deposit,
				  })
				: ISOtoHumanFriendly(installment.duedate);

		let chartAmount = amount;
		let chartTitle;
		if (installment?.type === 'Deposit') {
			chartAmount =
				installmentsData?.deposit_top_up_due_today > 0
					? installmentsData?.deposit_top_up_due_today
					: 0;

			chartTitle =
				installmentsData?.deposit_top_up_due_today > 0
					? t('common:depositTopUp')
					: t('common:deposit');
		}

		return {
			amount: chartAmount,
			date,
			deposit_top_up_due_today: installmentsData?.deposit_top_up_due_today,
			id: installment.instalment_number,
			sumAmount,
			title: chartTitle,
			total,
			type: installment?.type,
		};
	});
};

// Same with the getInstallmentListViaData but for after the payment is done with paid status
const getInstallmentListViaEditData = (
	installments: any,
	totalAmount: string,
	amountError: string,
	t: any,
	paidDeposit: boolean = false
) => {
	if (amountError) {
		return [];
	}
	const total = parseFloat(totalAmount);

	if (Number.isNaN(total) || total === 0) {
		return [];
	}

	let sumAmount = 0;
	return installments.map((installment: any) => {
		let amount;
		if (installment.status === 'pending') {
			amount = parseFloat(installment.outstanding);
		}
		if (installment.status === 'settled') {
			amount = parseFloat(installment.paid);
		}
		// @ts-ignore
		sumAmount += amount;
		let date;
		if (paidDeposit) {
			date =
				// eslint-disable-next-line no-nested-ternary
				parseFloat(installment.outstanding) === 0
					? t('common:paid')
					: ISOtoHumanFriendly(installment.duedate);
		} else {
			date =
				// eslint-disable-next-line no-nested-ternary
				installment.type === 'Deposit'
					? t('common:today')
					: ISOtoHumanFriendly(installment.duedate);
		}
		return {
			amount,
			date,
			id: installment.instalment_number,
			sumAmount,
			total,
		};
	});
};

// Gets the API installments and total, this needs to be merged with getInstallmentListViaData
const getRepaymentInstallmentList = (
	installments: IStoreAndAPIShared.IRepaymentInstallment[] | undefined,
	total: number
) => {
	if (!installments || Number.isNaN(total) || total === 0) {
		return [];
	}

	let sumAmount = 0;
	return installments.map(
		({
			amount,
			outstanding,
			paid,
			duedate,
			instalment_number: id,
			status,
			overdue_days: overdue,
		}) => {
			sumAmount += amount;
			return {
				amount,
				date: ISOtoHumanFriendly(duedate),
				id,
				outstanding,
				overdue,
				paid,
				status,
				sumAmount,
				total,
			};
		}
	);
};

const getIsDeposit = (data: IStoreAndAPIShared.IInstallment[]) => {
	if (data) {
		return data[0]?.type !== 'Monthly';
	}
	return undefined;
};

const getSingleInstalmentAmount = (data: IStoreAndAPIShared.IInstallment[]) => {
	if (data) {
		if (data[0]?.type === 'Monthly') {
			return data[0].amount;
		}
		return data[1]?.amount;
	}
	return undefined;
};

const getFinalInstalmentAmount = (data: IStoreAndAPIShared.IInstallment[]) => {
	if (data) {
		return data[data.length - 1].amount;
	}
	return '0.00';
};

const getDepositAmount = (data: IStoreAndAPIShared.IInstallment[]) => {
	if (data && data.length > 0) {
		if (data[0].type === 'Deposit') {
			return data[0].amount;
		}
		return '0.00';
	}
	return '0.00';
};

const depositPercentageSanitizer = (depositPercentage: string | undefined) => {
	if (depositPercentage) {
		const sanitizedPercentage = parseFloat(depositPercentage) * 100;
		if (Number.isInteger(sanitizedPercentage)) {
			return sanitizedPercentage;
		}
		return sanitizedPercentage.toFixed(1);
	}
	return 0;
};

const getPaidAmount = (data: IStoreAndAPIShared.IInstallment[]) => {
	let parsedAmount = 0;
	if (data && data.length > 0) {
		data.forEach((installment) => {
			parsedAmount += parseFloat(installment.paid);
		});
		return parsedAmount;
	}
	return '0.00';
};

const getIncreasedPaidAmount = (data: IStoreAndAPIShared.IInstallment[]) => data[0]?.amount;

const getOutstandingAmount = (data: IStoreAndAPIShared.IInstallment[]) => {
	let parsedAmount = 0;
	if (data && data.length > 0) {
		data.forEach((installment) => {
			parsedAmount += parseFloat(installment.outstanding);
		});
		return parsedAmount;
	}
	return '0.00';
};

const getOnlyInstalmentsLength = (data: IStoreAndAPIShared.IInstallment[]) => {
	if (data) {
		if (data[0]?.type === 'Monthly') {
			return data.length;
		}
		return data.length - 1;
	}
	return 0;
};

// Gets the backend product list and converts it to frontend product button list. There is a catch, for only thirdparty calculator we are disabling the products that under
// the min max amount. Also, we are showing tooltip for it. We need to find a way to implement this feature more cleanly
const getProductLabelsFromDynamicProducts = (
	products: IStoreAndAPIShared.IProduct[],
	amount?: number,
	t?: any,
	formatter?: any
) => {
	const itemList: IButtonGroup.IItem[] = [];

	products.forEach((product) => {
		let formattedMinAmount;
		if (formatter) {
			formattedMinAmount = formatter(product.min, {
				minimumFractionDigits: 0,
			});
		}

		itemList.push({
			disabled: amount && Number(product.min) ? amount < Number(product.min) : false,
			id: product.id,
			label: product.instalments,
			tooltipText:
				t && formatter ? t('forms:amount.validation.ruleMin', { amount: formattedMinAmount }) : '',
		});
	});

	return itemList;
};

export {
	depositPercentageSanitizer,
	getDepositAmount,
	getFinalInstalmentAmount,
	getIncreasedPaidAmount,
	getInstallmentListStatic,
	getInstallmentListViaData,
	getInstallmentListViaEditAmountData,
	getInstallmentListViaEditData,
	getIsDeposit,
	getOnlyInstalmentsLength,
	getOutstandingAmount,
	getPaidAmount,
	getProductLabelsFromDynamicProducts,
	getProductList,
	getRepaymentInstallmentList,
	getSingleInstalmentAmount,
	MAX_INSTALLMENT,
	TOTAL_PAYMENT,
};
