import { useTranslation } from 'next-i18next';

export type FormatOptions = {
	decimals?: number;
	style?: 'decimal' | 'currency';
	currencySign?: 'standard' | 'accounting';
	currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name';
	minimumFractionDigits?: number;
};

export interface IUseCurrency {
	isoCode?: string;
	currencyCode?: string;
}

const useCurrencyFormat = (currency?: IUseCurrency) => {
	const { t } = useTranslation('common');

	return (value: any, options?: FormatOptions) => {
		let isoCode = t('currency.isoCode');
		let currencyCode = t('currency.currencyCode');
		if (currency?.isoCode && currency?.currencyCode) {
			isoCode = currency.isoCode;
			currencyCode = currency.currencyCode;
		}

		const numberFormat = new Intl.NumberFormat(isoCode, {
			currency: currencyCode,
			currencyDisplay: options?.currencyDisplay,
			currencySign: options?.currencySign,
			maximumFractionDigits: options?.decimals ?? 2,
			minimumFractionDigits: options?.minimumFractionDigits ?? 0,
			style: options?.style ?? 'currency',
		});
		return numberFormat.format(value);
	};
};

export default useCurrencyFormat;
